/**
 * This file is for utility functions that create or modify
 * StacSearch objects
 */

import { log } from '@methanesat/log';
import { getCollectionName } from '.';
import { DateRanges } from '../../hooks';
import {
    AreaEmissionsProducts,
    Platforms,
    STACCollection,
    SortDirection,
    StacFilter,
    StacFormattedBbox,
    StacSearch,
    StacSearchField,
    StacSearchSorter,
    isSTACCollectionId
} from '../../types';
import { getMonthEnd, getMonthStart, getQuarterEnd, getQuarterStart } from '../time';

export const getBasicSearch = (): StacSearch => {
    return structuredClone(BASIC_SEARCH);
};

/**
 * Type guard for checking if a value is a STAC filter.
 */
export const isSTACFilter = (maybeFilter: unknown): maybeFilter is StacFilter =>
    maybeFilter !== null &&
    typeof maybeFilter === 'object' &&
    typeof (maybeFilter as StacFilter).op === 'string' &&
    Array.isArray((maybeFilter as StacFilter).args);

/**
 * Type guard for checking if a value is STAC collections.
 */
export const isSTACCollections = (maybeCollections: unknown): maybeCollections is STACCollection[] =>
    Array.isArray(maybeCollections) && maybeCollections.every((collection) => isSTACCollectionId(collection));

/**
 * Type guard for checking if a value is STAC sortby.
 */
export const isSTACSortby = (maybeSortby: unknown): maybeSortby is StacSearchSorter[] =>
    maybeSortby !== null &&
    Array.isArray(maybeSortby) &&
    typeof (maybeSortby[0] as StacSearchSorter).field === 'string' &&
    typeof (maybeSortby[0] as StacSearchSorter).direction !== 'undefined';

/**
 * Type guard for checking if a value is STAC fields.
 */
export const isSTACFields = (maybeFields: unknown): maybeFields is StacSearchField =>
    maybeFields !== null &&
    typeof maybeFields === 'object' &&
    Array.isArray((maybeFields as StacSearchField).include) &&
    Array.isArray((maybeFields as StacSearchField).exclude);

/**
 * Builds a STAC search filter
 * Note: the logic here is focused on filtering for a property=val queries (e.g. when searching for
 * target id).  It won't handle more complex queries.
 *
 * See https://api.stacspec.org/v1.0.0-beta.3/item-search/#tag/Item-Search/operation/getItemSearch
 *
 * @example
 * formatFilterForGET({ op: '=', args: [{ property: 'a' }, b] });
 * // -> `a='b'`
 */
export const formatFilterForGET = (filter: StacFilter): string => {
    const { op: operator, args } = filter;
    const [key, rawVal] = args;

    /**
     * Needs to be quoted or else CQL2 mistakenly thinks the dash `-` in `mair-target1` is a
     * logical operator.
     *
     * Single quotes don't need to be escaped, they're unreserved characters in URIs, see
     * `2.3. Unreserved Characters` in https://www.ietf.org/rfc/rfc2396.txt
     */
    const val = `'${rawVal}'`;

    return `${key.property}${operator}${val}`;
};

/**
 * Builds a STAC search sortby.
 * Note: GET output currently only supports sorting by one field (STAC search itself supports
 * sorting by multiple fields).
 *
 * See https://api.stacspec.org/v1.0.0-beta.3/item-search/#tag/Item-Search/operation/getItemSearch
 *
 * @example
 * formatSortbyForGET([ { field: 'properties.end_datetime', direction: SortDirection.Ascending } ]);
 * // -> '+properties.end_datetime'
 */
export const formatSortbyForGET = (stacSearchSorter: StacSearchSorter[]) => {
    const { field, direction } = stacSearchSorter[0];

    // `-` is descending, `+` is ascending
    return `${direction === SortDirection.Decending ? '-' : '+'}${field}`;
};

/**
 * Formats collections for a STAC search GET request.
 *
 * See https://api.stacspec.org/v1.0.0-beta.3/item-search/#tag/Item-Search/operation/getItemSearch
 *
 * @example
 * formatCollectionsForGET([STACCollection.MethaneSAT_Level4, STACCollection.MethaneAIR_Level4]);
 * // -> 'MethaneSAT_Level4,MethaneAIR_Level4'
 */
export const formatCollectionsForGET = (collections: STACCollection[]) => collections.join(',');

const addExcludePrefix = (STACField: string) => `-${STACField}`;

/**
 * Formats STAC search fields for a GET request.
 * See https://api.stacspec.org/v1.0.0-beta.3/item-search/#tag/Item-Search/operation/getItemSearch
 *
 * @example
 * formatFieldsForGET({ include: ['a', 'b'], exclude: ['c', 'd'] });
 * // -> 'a,b,-c,-d'
 */
export const formatFieldsForGET = (fields: StacSearchField) => {
    const { include: rawInclude, exclude: rawExclude } = fields;

    const include = Array.isArray(rawInclude) ? rawInclude : [];

    // Exclude fields are prefixed with `-`.
    const exclude = Array.isArray(rawExclude) ? rawExclude.map(addExcludePrefix) : [];

    return [...include, ...exclude].join(',');
};

export const formatBbox = (bbox: number[][]): StacFormattedBbox => {
    const corner1 = bbox[0];
    const corner2 = bbox[2];

    const maxLng = Math.max(corner1[0], corner2[0]);
    const maxLat = Math.max(corner1[1], corner2[1]);
    const minLng = Math.min(corner1[0], corner2[0]);
    const minLat = Math.min(corner1[1], corner2[1]);

    return [minLng, minLat, maxLng, maxLat];
};

/**
 *
 * @param date the date on which to base the search. The returned stac search will request the entire calendar month or quarter that includes the given date.
 * @param search the STAC search object to be modified
 * @param dateRange the length of time - ex. `DateRanges.Month`
 * @returns STAC search object, which can be passed to the `/stac/search` endpoint as the request body
 */
export const getTimeBoundStacSearch = (date: Date, search: StacSearch, dateRange: DateRanges) => {
    const clonedSearch = structuredClone(search);
    if (dateRange === DateRanges.Month) {
        clonedSearch.datetime = getDatetimeOneMonthRangeString(date);
    } else if (dateRange === DateRanges.Quarter) {
        clonedSearch.datetime = getDatetimeQuarterlyRangeString(date);
    } else {
        log.error(`Cannot search using the timeframe ${dateRange}`);
    }
    return clonedSearch;
};

/**
 * Get a 24hr day range
 * @param endDate
 * @returns
 */
export const getSingleDayRangeString = (endDate: Date) => {
    const start = new Date(endDate.getTime());
    // set time to midnight
    start.setUTCHours(0, 0, 0, 0);
    const end = new Date(start.getTime());
    end.setUTCDate(endDate.getUTCDate() + 1);

    const startString = start.toISOString();
    const endString = end.toISOString();
    return `${startString}/${endString}`;
};

export const getMonthEndString = (inputDate: Date) => {
    const monthEnd = getMonthEnd(inputDate);
    return monthEnd.toISOString();
};

/**
 * Get a datetime range string for a single calendar month
 * For STAC searches
 * Returns a range of one month based on the UTC date of @date
 *
 * Example:
 * `getDatetimeOneMonthRangeString(new Date('Feb 14 2023'))`
 * returns string for Feb 1 - Feb 28 2023:
 * `"2023-02-01T00:00:00.000Z/2023-02-28T23:59:59.999Z"`
 */
export const getDatetimeOneMonthRangeString = (date: Date): string => {
    const startDate = getMonthStart(date);
    const startString = startDate.toISOString();

    const endDate = getMonthEnd(date);
    const endString = endDate.toISOString();

    return `${startString}/${endString}`;
};

export const getDatetimeQuarterlyRangeString = (date: Date): string => {
    const startDate = getQuarterStart(date);
    const startString = startDate.toISOString();

    const endDate = getQuarterEnd(date);
    const endString = endDate.toISOString();

    return `${startString}/${endString}`;
};

export const getDateRangeFromPlatform = (platform: Platforms) => {
    return platform === Platforms.MSAT ? DateRanges.Quarter : DateRanges.Month;
};

export const getDateRangeEnd = (date: number, dateRange: DateRanges) => {
    if (dateRange === DateRanges.Quarter) return getQuarterEnd(new Date(date));
    return getMonthEnd(new Date(date));
};

export const getDateRangeStart = (date: number, dateRange: DateRanges) => {
    if (dateRange === DateRanges.Quarter) return getQuarterStart(new Date(date));
    return getMonthStart(new Date(date));
};
/**
 * Default Search parameters
 */
export const BASIC_SEARCH: StacSearch = {
    collections: [STACCollection.MethaneAIR_Level4, STACCollection.MethaneSAT_Level4],
    limit: 100,
    fields: {
        include: ['properties', 'collection', 'links', 'geometry', 'assets'],
        exclude: [
            'properties.proj:epsg',
            'properties.proj:wkt2',
            'properties.proj:transform',
            'properties.proj:bbox',
            'properties.proj:shape',
            'links'
        ]
    },
    sortby: [
        {
            field: 'properties.end_datetime',
            direction: SortDirection.Decending
        }
    ]
};

/**
 * Modify search params to query around a given point
 * @param lng longitude
 * @param lat latitude
 * @param search other serach parameters
 * @returns
 */
export const modifySearchByPoint = (lng: number, lat: number, search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.intersects = {
        type: 'Point',
        coordinates: [lng, lat]
    };
    return search;
};

/**
 * Modify search params to query around a given bounding box
 * @param bbox bounding box to intersect
 * @param search other search parameters
 * @returns
 */
export const modifySearchByBoundingBox = (bbox: number[][], search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.bbox = formatBbox(bbox);
    return search;
};

/**
 * Modify the item the search will perform for
 * @param itemId the item id to search for
 */
export const modifySearchItem = (itemId: string, search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.ids = [itemId];
    return search;
};

/**
 * Modify the collection the search will perform for
 * @param product Data processing level (L3 is concentrations, L4 is emissions).
 * @param platform if you want Sat or Air data (will not provide both as basic search will do this anyway for l4)
 */
export const modifySearchCollection = (
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT,
    search?: StacSearch
) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.collections = [getCollectionName(product, platform)];
    return search;
};
