import { ampli } from '../ampli';
import { AMPLITUDE_ENVIRONMENT, ANALYTICS_LOGS_TO_CONSOLE, ANALYTICS_SERVER_URL } from '../environmentVariables';

export const analytics = ampli;

// This endpoint is used when logging to the console
const NEXT_DEV_ENDPOINT = '/api/dev-analytics';

/**
 * Loads amplitude analytics. Depending on the environment variables, it'll either
 * log as a string, send to amplitude, or perform no action.
 */
export const loadAnalytics = (userId: string) => {
    ampli.load({
        client: {
            configuration: {
                // These are fields that amplitude tracks by default. This
                // enables tracking only for information that we want.
                defaultTracking: {
                    attribution: true,
                    fileDownloads: false,
                    formInteractions: false
                },
                // Disables the use of browser cookies and local storage.
                identityStorage: 'none',
                // 4 is debug logs meaning every log that amplitude logs.
                // https://github.com/amplitude/Amplitude-TypeScript/blob/c6355eff3f8e366bee43b3af5f0d2c57d90941d5/packages/analytics-types/src/logger.ts#L10
                logLevel: ANALYTICS_LOGS_TO_CONSOLE ? 4 : undefined,
                // The amplitude sdk must hit an endpoint so when we only want to log to the console,
                // we use a dummy endpoint.
                serverUrl: ANALYTICS_LOGS_TO_CONSOLE ? NEXT_DEV_ENDPOINT : ANALYTICS_SERVER_URL,
                // Disables location based tracking
                trackingOptions: {
                    ipAddress: false
                },
                // For logging to the console, we are using beacon so that we don't have
                // to worry about the respone of the server. The server is a mocked
                // endpoint so the response doesn't matter.
                transport: ANALYTICS_LOGS_TO_CONSOLE ? 'beacon' : undefined
            }
        },
        // If we don't want to use analytics, we can disable it with this flag.
        disabled: !AMPLITUDE_ENVIRONMENT && !ANALYTICS_LOGS_TO_CONSOLE,
        // If we are logging locally, amplitude still wants an environment.
        environment: AMPLITUDE_ENVIRONMENT ?? 'development'
    });
    // For each page refresh, sets a new user id. This will be the same
    // for airbrake
    ampli.identify(userId);
};
