import { envToBoolean, envToReasonablySizedString } from './utils';

export const INFRASTRUCTURE_API_BASE_URL = envToReasonablySizedString(
    process.env.NEXT_PUBLIC_INFRASTRUCTURE_API_BASE_URL
);
export const INFRASTRUCTURE_STATIC_TILE_URL = envToReasonablySizedString(
    process.env.NEXT_PUBLIC_INFRASTRUCTURE_STATIC_TILE_URL
);
export const INFRASTRUCTURE_DYNAMIC_TILE_URL = envToReasonablySizedString(
    process.env.NEXT_PUBLIC_INFRASTRUCTURE_DYNAMIC_TILE_URL
);
export const TITILER_URL = envToReasonablySizedString(process.env.NEXT_PUBLIC_TITILER_URL);

export const EMISSION_DB_URL = envToReasonablySizedString(process.env.NEXT_PUBLIC_EMISSION_DB_URL);

export const DISABLE_LAYER_ERRORS = envToBoolean(process.env.NEXT_PUBLIC_DISABLE_LAYER_ERRORS);

export const SHOW_L3_OPTION = envToBoolean(process.env.NEXT_PUBLIC_SHOW_L3_OPTION);

export const HEADER_MSAT_PLATFORM_DROPDOWN_ENABLED = envToBoolean(
    process.env.NEXT_PUBLIC_HEADER_MSAT_PLATFORM_DROPDOWN_ENABLED
);

export const SHOW_CAPTURES_MISSING_TOTAL = envToBoolean(process.env.NEXT_PUBLIC_SHOW_CAPTURES_MISSING_TOTAL);
