import { MVTLayer } from '@methanesat/maps/src/layers';
import { Layer } from '@methanesat/maps/src/types';
import { isLayerIdAllowingMultipleSelections } from '../consts';
import {
    MSatPickInfo,
    OGILayerIds,
    OGIPointInfrastructureIds,
    MethaneLayerIds,
    PickInfoToPrioritize,
    AreaEmissionsProducts,
    Platforms
} from '../types';
import { getRasterData } from './emissionsRasters';

/**
 * Temporary workaround to only show pick info for one layer. Higher priority layers appear first
 * in this array. If a layer id does not appear in this array, it is still picked but prioritized last.
 */
const pickInfoPriority = [
    MethaneLayerIds.targets,
    OGILayerIds.pointInfrastructure,
    `${OGILayerIds.tileInfrastructure}-points`,
    MethaneLayerIds.plumeEmissionRate,
    OGILayerIds.tileInfrastructure,
    ...Object.values(OGIPointInfrastructureIds),
    OGILayerIds.pipelines,
    OGILayerIds.basins,
    MethaneLayerIds.areaEmissionRaster
];

export function isNoData<Info extends PickInfoToPrioritize>(pickInfo: Info) {
    const feature = pickInfo?.object;
    if (feature) {
        return feature.properties?.methane === null;
    }
    return false;
}

/**
 * Gets the prefix of the id if it is part of pickInfoPriority. This is because some
 * ids have an added suffix appended to the root of the id.
 *
 * @example
 * getRootId('area-emission-raster-uinta')
 * // returns 'area-emission-raster'
 */
export const getRootId = (id: string) => {
    const rootId = pickInfoPriority.find((pickInfoId) => id.startsWith(pickInfoId.toString()));
    return rootId ?? id;
};

/**
 * From an array of pick infos, returns the pick info based on the priority order,
 * removing infos with negative emissions entirely.
 * @example
 * pickPrioritizedFilteredLayer([{layer: {id: 'unknown'}}, {layer: {id: OGILayerIds.pointInfrastructure}}])
 * // returns {layer: {id: OGILayerIds.pointInfrastructure}}
 */
export const pickPrioritizedFilteredLayer = <Info extends PickInfoToPrioritize>(pickInfos: Info[]): Info | undefined =>
    sortPickInfos(pickInfos.filter((pickInfo) => !isNoData(pickInfo)))[0];

/**
 * From an array of pickInfos, return an array of pickInfos that match the given layerId.
 *
 * For any layer to allow multiple features to be selected at a time, the layer id must be included
 * in `LAYERS_ALLOWING_MULTIPLE_FEATURE_SELECTION`.
 *
 * Each deck.gl must also be set up specifically to handle highlighting multiple features at once
 * (see `selectInfrastructureTileLayer` in file `app/src/reducers/pages/emissions-map.ts`)
 */
export const pickMultiplePrioritizedFilteredLayers = <Info extends PickInfoToPrioritize>(
    pickInfos: Info[],
    layerId?: string
) => {
    if (!layerId || !isLayerIdAllowingMultipleSelections(layerId)) return null;
    return (
        pickInfos.filter((pickInfo) => {
            return pickInfo.layer.id === layerId && typeof pickInfo.object === 'object' && pickInfo.object;
        }) || null
    );
};

/**
 * From an array of pick infos, returns the pick info based on the priority order.
 *
 * Gets top priority pick info and checks it for validity.
 * If the top priority info is invalid (invalid info.object, negative methane data, bad/missing raster data),
 * recursively checks the remaining infos for validity and returns the first valid info.
 */
export const pickValidTopPriorityInfo = async (
    infoList: PickInfoToPrioritize[],
    endDate: Date,
    product: AreaEmissionsProducts,
    platform: Platforms
): Promise<PickInfoToPrioritize | null> => {
    const sortedList = sortPickInfos(infoList);
    if (!sortedList.length) return null;

    const [topPickInfo, ...remainders] = sortedList;

    // bitmap handling
    if (topPickInfo.bitmap) {
        const rasterData = await getRasterData(topPickInfo, endDate, product, platform);
        const updatedInfo = { ...topPickInfo, object: rasterData } as PickInfoToPrioritize;
        if (rasterData && !isNoData(updatedInfo)) {
            return updatedInfo;
        }
        return await pickValidTopPriorityInfo(remainders, endDate, product, platform);
    }

    // non-bitmap handling
    if (typeof topPickInfo.object === 'object' && topPickInfo.object && !isNoData(topPickInfo)) {
        return topPickInfo;
    }
    return await pickValidTopPriorityInfo(remainders, endDate, product, platform);
};

/**
 * From an array of pick infos, returns the pick info based on the priority order.
 * @example
 * sortPickInfos([{layer: {id: 'unknown'}}, {layer: {id: OGILayerIds.pointInfrastructure}}])
 * // returns [{layer: {id: OGILayerIds.pointInfrastructure}}, {layer: {id: 'unknown'}}]
 */
export const sortPickInfos = <Info extends PickInfoToPrioritize>(pickInfos: Info[]): Info[] => {
    return (
        pickInfos
            // order the remaining infos by priority
            .sort(({ layer: { id: idA } }, { layer: { id: idB } }) => {
                const priorityA = pickInfoPriority.indexOf(getRootId(idA));
                const priorityB = pickInfoPriority.indexOf(getRootId(idB));
                // unknown (un-prioritized) layers should always be moved to the end
                if (priorityA === -1) return 1;
                if (priorityB === -1) return -1;
                return priorityA - priorityB;
            })
    );
};

function isMVTLayer(layer: Layer): layer is MVTLayer {
    return layer.props && 'uniqueIdProperty' in layer.props;
}

/**
 * Gets the id of an object from feature or uniqueIdProperty.
 */
export function getFeatureId(info: MSatPickInfo) {
    const feature = info.object;

    if (feature.properties && isMVTLayer(info.layer) && info.layer.props.uniqueIdProperty) {
        const uniqueIdProperty = info.layer.props.uniqueIdProperty;
        return feature.properties[uniqueIdProperty];
    }

    if ('id' in feature) {
        return feature.id;
    }

    return undefined;
}
