import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Polygon } from 'geojson';

import { black } from '@methanesat/colors';
import { TextLayer, IconLayer, Layer, PolygonLayer, Position } from '@methanesat/maps';

import { centroid, polygon } from '@turf/turf';

import { DEFAULT_TARGET_FILL_COLOR, ZOOM_THRESHOLDS, getFilledTargetColor } from '../../../consts';
import {
    selectActiveCapture,
    selectMethaneTargetLayerConfig,
    selectPlatform,
    selectTargetDates
} from '../../../reducers';
import { CaptureFeature, CaptureFeatureProperties, StacSearchResponse } from '../../../types';
import { getMonthDay } from '../../../utils';

import { getCapturesToDisplay, useGetCapturesWithinViewport } from '../../data';

/**
 * Builds the deck.gl layer for the MSat target layer
 */
export const useTargetLayers = (
    zoom: number,
    data: StacSearchResponse<Polygon, CaptureFeatureProperties> | null
): Layer[] => {
    const {
        highlightedFeatureId,
        highlightedObjectIndex: _highlightedObjectIndex,
        ...layerProps
    } = useSelector(selectMethaneTargetLayerConfig);

    const [hasUpdatedLayers, setHasUpdatedLayers] = useState(false);
    const [captures, setCaptures] = useState<CaptureFeature[]>([]);

    const activeCapture = useSelector(selectActiveCapture);
    const visibleCaptures = useGetCapturesWithinViewport(data);
    const [singleTargetInViewId, setsingleTargetInViewId] = useState<null | string>(null);

    // determine time range
    // create new time item "string_from/string_til"
    // modify the search and send
    const platform = useSelector(selectPlatform);
    const targetDates = useSelector(selectTargetDates);
    // Get captures to display when the data is ready or when it changes
    useEffect(() => {
        if (data && data.features) {
            setHasUpdatedLayers(false);

            const captures = getCapturesToDisplay(data, targetDates) || [];
            setCaptures(captures);
            setHasUpdatedLayers(true);

            if (visibleCaptures.length === 1) {
                setsingleTargetInViewId(visibleCaptures[0].id);
            } else {
                setsingleTargetInViewId(null);
            }
        }
    }, [data, targetDates, visibleCaptures]);

    const totalMethane = (d: CaptureFeature) => {
        return d.properties.net_total;
    };

    const getDatePixelOffset = (monthlyCaptureCount: number): [number, number] => {
        if (monthlyCaptureCount > 1) {
            return [0, 18];
        }
        return [0, 0];
    };

    const ICON_MAPPING = {
        marker: { x: 0, y: 0, width: 62, height: 63 }
    };

    const PILL_ICON_MAPPING = {
        marker: { x: 0, y: 0, width: 50, height: 22 }
    };

    const multiCapture: CaptureFeature[] = [];
    captures.forEach((capture) => {
        if (capture.properties.monthlyCaptureCount > 1) {
            multiCapture.push(capture);
        }
    });

    const multipleCaptureIcon = new IconLayer({
        data: hasUpdatedLayers ? multiCapture : undefined,
        getIcon: () => 'marker',
        getPosition: (d) => {
            const targetPolygon = polygon(d.geometry.coordinates);
            const targetCentroid = centroid(targetPolygon);
            return targetCentroid.geometry.coordinates as Position;
        },
        getSize: 36,
        iconAtlas: '/img/multi-target-icon.svg',
        iconMapping: ICON_MAPPING,
        id: layerProps.id + 'multiple-capture-icon',
        minZoom: 6,
        pickable: true,
        visible:
            zoom > ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON &&
            zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON
    });

    const blackPillIcon = new IconLayer({
        data: captures,
        getIcon: () => 'marker',
        getPosition: (d) => {
            const targetPolygon = polygon(d.geometry.coordinates);
            const targetCentroid = centroid(targetPolygon);
            return targetCentroid.geometry.coordinates as Position;
        },
        getSize: 16,
        getPixelOffset: (d) => getDatePixelOffset(d.properties.monthlyCaptureCount),
        iconAtlas: '/img/black-pill-icon.svg',
        iconMapping: PILL_ICON_MAPPING,
        id: layerProps.id + 'black-pill-icon',
        minZoom: 6,
        pickable: true,
        visible:
            zoom > ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_TARGET_DATE_LABEL &&
            zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET_DATE_LABEL
    });

    const dateLabel = new TextLayer({
        data: captures,
        getPosition: (d) => {
            const targetPolygon = polygon(d.geometry.coordinates);
            const targetCentroid = centroid(targetPolygon);
            return targetCentroid.geometry.coordinates as Position;
        },
        getSize: 8,
        getText: (d) => getMonthDay(d.properties.datetime).toUpperCase(),
        getPixelOffset: (d) => getDatePixelOffset(d.properties.monthlyCaptureCount),
        fontFamily: 'Roboto',
        getColor: [255, 255, 255],
        getTextAnchor: 'middle',
        id: layerProps.id + 'date-icon',
        minZoom: 6,
        pickable: true,
        visible:
            zoom > ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_TARGET_DATE_LABEL &&
            zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET_DATE_LABEL
    });

    const getLineWidth = (d: CaptureFeature) => {
        const shouldHighlight = d.id === (highlightedFeatureId || singleTargetInViewId || activeCapture?.itemId);
        return shouldHighlight ? 3 : 1;
    };

    const targetFilledPolygons = new PolygonLayer<CaptureFeature>({
        ...layerProps,
        id: `${layerProps.id}-filled-polygon`,
        data: hasUpdatedLayers ? captures : undefined,
        filled: true,
        getFillColor: (d) => {
            const hasTotal = !!totalMethane(d);
            const hasFill = zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET;

            return hasTotal && hasFill ? getFilledTargetColor(totalMethane(d), platform) : DEFAULT_TARGET_FILL_COLOR;
        },
        getPolygon: (d) => d?.geometry.coordinates,
        stroked: false,
        updateTriggers: { getLineWidth: highlightedFeatureId, getFillColor: zoom },
        visible: zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET
    });

    const targetOutlines = new PolygonLayer<CaptureFeature>({
        ...layerProps,
        id: `${layerProps.id}-outlines`,
        data: hasUpdatedLayers ? captures : undefined,
        filled: false,
        getLineColor: black,
        getLineWidth: getLineWidth,
        getPolygon: (d) => d?.geometry.coordinates,
        lineWidthMaxPixels: 5,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        pickable: zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET,
        stroked: true,
        updateTriggers: { getLineWidth: [highlightedFeatureId, singleTargetInViewId, activeCapture] },
        visible: true
    });

    return [targetFilledPolygons, targetOutlines, multipleCaptureIcon, blackPillIcon, dateLabel];
};
