// structuredClone polyfill - see https://github.com/zloirock/core-js?tab=readme-ov-file#structuredclone
import 'core-js/actual/structured-clone';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import NextHead from 'next/head';
import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import {
    ThemeProvider,
    CacheProvider,
    createEmotionCache,
    CssBaseline,
    EmotionCache,
    useMakeTheme
} from '@methanesat/ui-components';

import { UNKNOWN } from '../consts';
import { i18n, isInBrowserEnvironment, loadAnalytics, setInLocalStorage } from '../utils';
import store from '../store';
import { log } from '@methanesat/log';
import '../styles/global.css';
import '../styles/mapbox-scale-attrib-override.css';
import { I18nProvider } from '../components';
import { RefreshDialog } from '../components/RefreshDialog';
import {
    AIRBRAKE_PROJECT_ID,
    AIRBRAKE_PROJECT_KEY,
    BUILD_DATE_TIME,
    ENVIRONMENT,
    GIT_BRANCH,
    GIT_HASH,
    GITHUB_RUN_NUMBER,
    THEME_MODE,
    USE_CONSOLE_FOR_LOGS
} from '../environmentVariables';
import throttle from 'lodash/throttle';
import { areNotificationsLoading, NotificationMessage, NotificationsLoading } from '../types';

// @ts-expect-error no typings available for this polyfill
import toReversed from 'array.prototype.toreversed';

// es-shim API style polyfill for Array.prototype.toReversed()
// Shim will only add the function the to native prototype if it doesn't already exist.
// See https://github.com/es-shims/es-shim-api
toReversed.shim();

export interface CustomAppProps<T> extends AppProps<T> {
    emotionCache?: EmotionCache;
}

export interface I18nProps {
    dictionary: Record<string, unknown>;
    language: string;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// Only initialize analytics and logs if we are running in the browser.
// We do not need to initialize when Nextjs is building the application.
if (isInBrowserEnvironment()) {
    // Give the analytics and error reporting the same id so that
    // we can gain more context when we debug errors. Our analytics
    // tracks more about what the user does in comparison to our error
    // reporting.

    // Note: only available in HTTPS
    // See https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
    const uuid =
        typeof window.crypto?.randomUUID === 'function' ? window.crypto?.randomUUID() : `${new Date().getTime()}`;

    loadAnalytics(uuid);

    log.init({
        projectId: AIRBRAKE_PROJECT_ID,
        projectKey: AIRBRAKE_PROJECT_KEY,
        environment: ENVIRONMENT,
        useConsole: USE_CONSOLE_FOR_LOGS,
        userId: uuid
    });
}

store.subscribe(
    // only update localStorage every 1000 ms, not on every state change
    throttle(() => {
        // notifications in the redux store
        const notifications: NotificationMessage[] | NotificationsLoading =
            store.getState().pages.emissions.mapInterface.notifications;

        // ensure we don't save the loading state to localStorage
        if (areNotificationsLoading(notifications)) return;

        // save only notifications that are set to store=true
        const notificationsToStore = notifications.filter((notification: NotificationMessage) => notification.store);

        setInLocalStorage('notifications', notificationsToStore);
    }, 1000)
);

/**
 * Custom App component to initialize pages.  Intended for elements that persist between
 * pages (e.g. global CSS).
 * See https://nextjs.org/docs/advanced-features/custom-app
 * Note that the emotionCache prop will be populated when performing server rendering but will
 * use the clientSideEmotionCache instance on the client for all pages
 */
function CustomApp({
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
}: CustomAppProps<I18nProps>): ReactElement {
    const theme = useMakeTheme({ themeMode: THEME_MODE });

    return (
        <CacheProvider value={emotionCache}>
            <NextHead>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta charSet="utf-8" />
                <meta name="author" content="MethaneSAT, LLC." />
                <link type="text/plain" rel="author" href="/humans.txt" />
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                {/* Fav icons and theme styling. */}
                <link
                    rel="shortcut icon"
                    type="image/png"
                    href="/favicons/favicon-96x96.png"
                    sizes="16x16 32x32 64x64"
                />
                <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="/favicons/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="/favicons/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="/favicons/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="/favicons/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
                <meta name="msapplication-TileImage" content="/favicons/ms-icon-144x144.png" />
                <meta
                    data-git-hash={GIT_HASH || UNKNOWN}
                    data-git-branch={GIT_BRANCH || UNKNOWN}
                    data-env={ENVIRONMENT || UNKNOWN}
                    data-run-number={GITHUB_RUN_NUMBER || UNKNOWN}
                    data-build-time={BUILD_DATE_TIME || UNKNOWN}
                />
            </NextHead>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <I18nProvider i18nInstance={i18n} lngDict={pageProps.dictionary} locale={pageProps.language}>
                        <>
                            <RefreshDialog />
                            <Component {...pageProps} />
                        </>
                    </I18nProvider>
                </ThemeProvider>
            </Provider>
        </CacheProvider>
    );
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    // Stringify the metric object so Airbrake can parse it.
    log.info(JSON.stringify(metric));
}

export default CustomApp;
