import { useMemo } from 'react';
import { OGI_NEAR_PLUME_RADIUS_M } from '../../consts';
import { INFRASTRUCTURE_API_BASE_URL } from '../../environmentVariables';
import {
    MSatPickInfo,
    OGILayerIds,
    OGITileFeatureProperties,
    PipelineFeatureProperties,
    PointOGIMGeoJSONProperties
} from '../../types';
import { useDataAPI } from './data';
import { OGINearPoint } from '../../types';

export const useOGIAPI = function <Data extends OGITileFeatureProperties>(
    info: MSatPickInfo<GeoJSON.Geometry, OGITileFeatureProperties | Data>
): { data: Data; loadingState?: boolean } | null {
    const objectId = info?.object.id;
    const layerId = info?.layer.id;
    const shouldFetch = layerId.includes(OGILayerIds.tileInfrastructure);

    const ogiMetadataUrl = useGetOGIMetadataUrl([objectId]);

    const fetchedMetadata = useDataAPI<Data[]>((shouldFetch && ogiMetadataUrl) || null);
    const mergedMetadata = fetchedMetadata && {
        ...fetchedMetadata[0],
        ...info.object.properties
    };

    let loadingState;

    if (typeof fetchedMetadata === 'undefined' && typeof ogiMetadataUrl === 'string') {
        loadingState = true;
    }

    const dataAndLoadingState = { loadingState: loadingState, data: mergedMetadata };

    /**
     * If the clicked layer isn't the OGI tile layer, the data is static
     * and therefore already includes all the metadata available. Run-time
     * typing isn't possible, so ignoring the type error on the next line
     * is necessary.
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return shouldFetch ? dataAndLoadingState || null : { ...dataAndLoadingState, data: info.object.properties };
};

export const useGetOgiNearPlumes = ({ longitude, latitude }: { longitude: number; latitude: number }) => {
    // Fetch the OGIM data near plumes
    return useDataAPI<OGINearPoint[] | []>(
        `${INFRASTRUCTURE_API_BASE_URL}/rpc/features_within_radius?lng_center=${longitude}&lat_center=${latitude}&radius_m=${OGI_NEAR_PLUME_RADIUS_M}`
    );
};

/**
 *
 * @param ogimIds array of strings or numbers
 * @returns encode URI querystring component to request OGI metadata
 *
 * @example
 * getOGIMetadataQueryString([1234,94872])
 *
 * returns "ogim_id=%7B1234,94872%7D"
 * The decoded URI is "ogim_id={1234,94872}"
 */
const useGetOGIMetadataUrl = (ogimIds: (number | string)[]) => {
    const baseURL = `${INFRASTRUCTURE_API_BASE_URL}/rpc/feature_metadata`;
    const url = useMemo(() => {
        if (!ogimIds || !ogimIds.length) return null;
        const queryString = `ogim_id=${encodeURI(`{${ogimIds}}`)}`;
        return `${baseURL}?${queryString}`;
    }, [ogimIds, baseURL]);
    return url;
};

export const useGetOgiMetadata = ({ ogimIds }: { ogimIds: (number | string)[] }) => {
    const ogiURL = useGetOGIMetadataUrl(ogimIds);
    return useDataAPI<PipelineFeatureProperties[] | PointOGIMGeoJSONProperties[]>(ogiURL);
};
