import { AreaEmissionsProducts, Platforms, STACCollection } from '../../types';

export const getProductFromCollectionId = (collectionId: string) => {
    if (collectionId.includes('Level4')) return AreaEmissionsProducts.l4;
    if (collectionId.includes('Level3')) return AreaEmissionsProducts.l3;
    throw new Error(`Unsupported product in collectionId ${collectionId}`);
};

/**
 * Determines collection name based on data processing level and instrument.
 * @param product Data processing level (L3 is concentrations, L4 is emissions).  Defaults to L4.
 * @param platform Whether the instrument is satellite-based or aircraft-based.
 */
export const getCollectionName = (
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT
): STACCollection => {
    if (platform === Platforms.MSAT) {
        // MethaneSAT (satellite-based instrument)
        return product === AreaEmissionsProducts.l4
            ? STACCollection.MethaneSAT_Level4
            : STACCollection.MethaneSAT_Level3;
    } else {
        // MethaneAIR (aircraft-based instrument)
        return product === AreaEmissionsProducts.l4
            ? STACCollection.MethaneAIR_Level4
            : STACCollection.MethaneAIR_Level3;
    }
};
