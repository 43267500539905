import { Feature, FeatureCollection, Polygon } from 'geojson';

import { BaseQueryApi, createApi, fetchBaseQuery, FetchBaseQueryArgs } from '@reduxjs/toolkit/query/react';

import { EMISSION_DB_URL, IN_IAP, SHOW_CAPTURES_MISSING_TOTAL } from '../../environmentVariables';
import { CaptureFeatureProperties, StacFeature, StacFilterLang, StacSearch, StacSearchResponse } from '../../types';
import { Notifier } from '../../utils/Notifier';
import { buffer } from '@turf/turf';
import {
    formatCollectionsForGET,
    formatFieldsForGET,
    formatFilterForGET,
    formatSortbyForGET,
    isSTACCollections,
    isSTACFields,
    isSTACFilter,
    isSTACSortby
} from '../../utils';

/**
Testing notes: 

* Any new endpoints MUST be added to makeApiSlice in packages/app/test/unit/index.tsx
Omitting endpoints there will cause tests for the component that uses them to fail 
(generally, they'll fail to run at all).

* Any new api slices must be added to the store in wrappedRender in 
packages/app/test/unit/index.tsx (add to both the middleware chain and the list of reducers).

*/

const filterCapturesWithoutTotalEmissions = (f: Feature<Polygon, CaptureFeatureProperties>) =>
    SHOW_CAPTURES_MISSING_TOTAL || !!f.properties.net_total;

/** Custom base stac query to add IAP headers, necessary for dev & staging deployments */
const fetchCustomBaseStacQuery = (arg: FetchBaseQueryArgs) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (args: any, api: BaseQueryApi, extraOptions: any) => {
        const defaultBaseQuery = fetchBaseQuery({
            ...arg,
            prepareHeaders: (headers: Headers) => {
                if (IN_IAP) {
                    headers.set('X-Requested-With', 'XMLHttpRequest');
                }
                return headers;
            }
        });
        try {
            const result = await defaultBaseQuery(args, api, extraOptions);
            if (IN_IAP && result.error && result.error.status === 401) {
                new Notifier().notify();
            }
            return result;
        } catch (error) {
            return { error: { status: 400, data: error } };
        }
    };
};

export const stacAPISlice = createApi({
    reducerPath: 'stac',
    baseQuery: fetchCustomBaseStacQuery({
        baseUrl: EMISSION_DB_URL
    }),
    endpoints: (builder) => ({
        getStacSearch: builder.query<StacSearchResponse<Polygon, CaptureFeatureProperties>, StacSearch | null>({
            /**
             * Note: for STAC search prefer using GET to take advantage of the CDN.  Otherwise using
             * POST with no CDN greatly limits our max concurrent users.  For simple search queries
             * it's recommended to use GET, especially for primary features that are called by every
             * user of Portal.
             *
             * In the future for secondary features not used by every user, it might be worth
             * considering adding a separate POST query for more full-featured filtering.
             *
             * See https://api.stacspec.org/v1.0.0-beta.3/item-search/#tag/Item-Search/operation/getItemSearch
             */
            query: (stacSearch) => {
                if (stacSearch === null) {
                    return;
                }

                const url = new URL(`${EMISSION_DB_URL}/search`, window.location.origin);

                /**
                 * Formats STAC search parameters for GET or POST.
                 */

                // Iterates through each stacSearch key and adds the value to the GET URL search
                // params.
                Object.entries(stacSearch).forEach(([key, val]) => {
                    // Some complex objects require special transforms to pass them into a URL for
                    // STAC search GET.
                    if (key === 'collections' && isSTACCollections(val)) {
                        url.searchParams.set('collections', formatCollectionsForGET(val));
                    } else if (key === 'filter' && isSTACFilter(val)) {
                        url.searchParams.set('filter', formatFilterForGET(val));

                        // Since a filter is set and we're using GET, set filter-lang to CQL2 text.
                        url.searchParams.set('filter-lang', StacFilterLang.cql2Text);
                    } else if (key === 'sortby' && isSTACSortby(val)) {
                        url.searchParams.set('sortby', formatSortbyForGET(val));
                    } else if (key === 'fields' && isSTACFields(val)) {
                        url.searchParams.set('fields', formatFieldsForGET(val));
                    } else {
                        // Fallthrough: val is already a primitive that doesn't need a transform.
                        url.searchParams.set(key, `${val}`);
                    }
                });

                return {
                    url: `${url}`,
                    method: 'GET'
                };
            },
            transformResponse: (response: StacSearchResponse<Polygon, CaptureFeatureProperties>) => {
                const filteredCaptures = response.features?.filter(filterCapturesWithoutTotalEmissions);
                return {
                    ...response,
                    features: filteredCaptures
                };
            }
        }),
        getAllStacItemsForCollection: builder.query<
            FeatureCollection<Polygon, CaptureFeatureProperties> | undefined,
            { collectionId: string }
        >({
            query: ({ collectionId }) => {
                return { url: `/collections/${collectionId}/items?limit=1000` };
            },
            transformResponse: (response: FeatureCollection<Polygon, CaptureFeatureProperties>) => {
                const filteredCaptures = response.features?.filter(filterCapturesWithoutTotalEmissions);
                const updatedResponse = {
                    ...response,
                    features: filteredCaptures
                };
                return buffer(updatedResponse, 1, { units: 'kilometers' }) as FeatureCollection<
                    Polygon,
                    CaptureFeatureProperties
                >;
            }
        }),
        getStacItem: builder.query<StacFeature, { collectionId: string; itemId: string }>({
            query: ({ collectionId, itemId }) => {
                return {
                    url: `/collections/${collectionId}/items/${itemId}`
                };
            }
        })
    })
});

export const { useGetStacSearchQuery, useGetStacItemQuery, useGetAllStacItemsForCollectionQuery } = stacAPISlice;
