import GeoJSON, { GeoJsonProperties } from 'geojson';

import { Color, InitialMapViewState, LayerProps, MapStyles, MVTLayerProps, UNITS } from '@methanesat/maps';
import { AlertSeverityArray, AlertSeverity } from '@methanesat/ui-components';

import { BitmapInfo, PlumeProperties, STACCollection, StacFeature } from '../types';
import { AreaEmissionsProducts, MethaneLayerIds, UrlFeatureTypes } from './MapLayers';
import { OGILayerIds, PipelineFeatureProperties, PointOGIMGeoJSONProperties } from './OGI';

export type MethaneDiffuseRangeFilterKey = 'bin1' | 'bin2' | 'bin3' | 'bin4' | 'bin5';
export type MethaneDistinctRangeFilterKey = 'bin1' | 'bin2' | 'bin3';

/**  Only the properties from MapViewState that can be serialized and therefore saved in the redux store */
export type ViewStatePropsRedux = InitialMapViewState;

/**
 * The serializable properties from MapViewState (via ViewStatePropsRedux) as either numbers or strings.
 * The querystring can provide the initial viewstate on load and does so using strings.
 */
export type ViewStatePropsQueryString = { [key in keyof ViewStatePropsRedux]: string | number | number[] };

export const NothingHighlighted = {
    highlightedFeatureId: undefined,
    highlightedObjectIndex: -1
} as const;

export type HighlightedObject = GeoJSON.Feature | BitmapInfo | null;

interface LayerState extends Omit<LayerProps, 'id' | 'onDataLoad'> {
    enabled: boolean;
    highlightedObject?: HighlightedObject;
    opacity?: number;
}

interface InfrastructureGeoLayerState extends LayerState {
    getFillColor: Color;
    getLineColor: Color;
    getLineWidth: number;
    lineWidthMinPixels: number;
    lineWidthMaxPixels: number;
    lineWidthUnits: UNITS;
    stroked: boolean;
}

export enum Platforms {
    MSAT = 'MethaneSAT',
    MAIR = 'MethaneAIR'
}

/** Type guard to check whether an item is of the `Platforms` type */
export function isPlatform(value: unknown): value is Platforms {
    return Object.values(Platforms).includes(value as Platforms);
}

export type DateOption = { value: number; label: string; disabled?: boolean };

export type GeoJsonPointLayerState = InfrastructureGeoLayerState & {
    filled: boolean;
    getPointRadius: number;
    pointRadiusMaxPixels: number;
    pointRadiusMinPixels: number;
    pointRadiusUnits: UNITS;
};

export type GeoJsonLineLayerState = InfrastructureGeoLayerState;

export type GeoJsonPolygonLayerState = GeoJsonLineLayerState & GeoJsonPointLayerState;

export interface TargetLayerState extends GeoJsonPolygonLayerState {
    highlightedFeatureId?: string | null;
}

export interface AreaLayerState extends Omit<LayerState, 'highlightColor'> {
    filled?: boolean;
    filtersArea: MethaneDiffuseRangeFilterKey[];
    highlightColor?: Color;
    product: AreaEmissionsProducts;
    stroked?: boolean;
}

export interface FluxPlumeLayerState extends LayerState {
    filtersPlume: MethaneDiffuseRangeFilterKey[];
    highlightedFeatureId?: string | null;
}

export interface Operator {
    id: number;
    label: string;
}
export interface Category {
    id: (typeof OGILayerIds)[keyof typeof OGILayerIds];
}
export interface PreviousState {
    categories: Category[];
    operators: Operator[];
}
export interface MapFilters {
    categories: Category[];
    operators: Operator[];
    ogimFeatures: PipelineFeatureProperties[] | PointOGIMGeoJSONProperties[];
    previousState: PreviousState;
}

export type TileInfrastructureLayerState = Omit<LayerState, 'highlightedObjectIndex'> &
    Omit<Partial<MVTLayerProps>, 'highlightedFeatureId' | 'highlightedObjectIndex'> &
    Omit<Partial<InfrastructureGeoLayerState>, 'highlightedObjectIndex'> & {
        highlightedFeatureId?: MVTLayerProps['highlightedFeatureId'] | MVTLayerProps['highlightedFeatureId'][];
        highlightedObjectIndex?: MVTLayerProps['highlightedObjectIndex'] | MVTLayerProps['highlightedObjectIndex'][];
    };

export function getHighestAlertSeverity(alerts: AlertSeverity[]): AlertSeverity {
    return alerts.sort((a, b) => (AlertSeverityArray.indexOf(a) < AlertSeverityArray.indexOf(b) ? -1 : 1))[0];
}

export type StacFeatureWithLayerId<G extends GeoJSON.Geometry = GeoJSON.Geometry, P = GeoJsonProperties> = StacFeature<
    G,
    P
> & {
    layerId: string;
};

export interface NotificationMessage {
    /* attributes for all notifications */
    message: string;
    notificationVisible: boolean;
    read: boolean;
    severity: AlertSeverity;
    store: boolean;
    title?: string;
    /* for isSnackbar=true only */
    isSnackbar?: boolean;
    snackbarOpen?: boolean;
}
export interface NotificationsLoading {
    loading: true;
}
export const areNotificationsLoading = (
    notifications: NotificationMessage[] | NotificationsLoading
): notifications is NotificationsLoading => {
    if (Array.isArray(notifications)) return false;
    return notifications.loading;
};
/** Attributes of a map's state in redux */
// TODO: remove - https://methanesat.atlassian.net/browse/DP-4373
export interface MapState {
    /** The instrument used to capture the data displayed (MethaneAIR or MethaneSAT) */
    platform: Platforms;
    layers: {
        [MethaneLayerIds.areaEmissionRaster]: AreaLayerState;
        [MethaneLayerIds.plumeEmissionRate]: FluxPlumeLayerState;
        [OGILayerIds.pointInfrastructure]: GeoJsonPointLayerState;
        [OGILayerIds.pipelines]: GeoJsonLineLayerState;
        [MethaneLayerIds.targets]: TargetLayerState;
        [OGILayerIds.basins]: GeoJsonPolygonLayerState;
        [OGILayerIds.tileInfrastructure]: TileInfrastructureLayerState;
    };
    viewState: ViewStatePropsRedux;
    mapStyle?: (typeof MapStyles)[keyof typeof MapStyles];
    /** bounding box from geojson: [west, south, east, north] */
    bbox: GeoJSON.BBox;
    /** General map filters. */
    filters: MapFilters;
    /* All other notifications, not associated with snackbars */
    notifications: NotificationMessage[] | NotificationsLoading;
    /**
     * Based on Google Maps gestureHandling to handle interactivity with the map
     * https://developers.google.com/maps/documentation/javascript/interaction
     */
    gestureHandling: google.maps.MapOptions['gestureHandling'];
    ui: {
        isAreaEmissionsChipExpanded: boolean;
        isOgiOperatorFilterExpanded: boolean;
        isPlumeEmissionsChipExpanded: boolean;
        isOilAndGasTypeChipExpanded: boolean;
        isTargetEmissionsChipExpanded: boolean;
    };
    date: {
        global: number;
        // targetIds and their specific dates
        [targetId: string]: number | null | undefined;
    };
    selectedStacItem: {
        selectedStacCollectionId: STACCollection;
        selectedStacItemId: string;
        selectedFeatureType: string;
        selectedFeatureCoordinates: GeoJSON.Position;
    };
    selectedPlume: StacFeatureWithLayerId | null;
    urlParamsInitialized: boolean;
    fetchingDrawerInfo: boolean;
}

export type MapStateLayers = MapState['layers'];
export type MapStateLayerNames = keyof MapStateLayers;

export type MapStateLayerConfigs = MapStateLayers[MapStateLayerNames];

/** Properties from distinct and diffuse flux layers
 * that can be updated together, in any combination.
 * Not all properties are in common between distinct & diffuse layers
 * but the two layers can be udpated by one set of controls, so updates
 * are batched together.
 */
export type UpdatableEmissionRateLayerConfig = {
    /** For both diffuse and distinct layers */
    enabled?: boolean;
    /** Methane value filters for diffuse layers only */
    filtersArea?: MethaneDiffuseRangeFilterKey[];
    /** Methane value filters for distinct layers only */
    filtersPlume?: MethaneDiffuseRangeFilterKey[];
};
type InfrastructureLayerConfig = MapStateLayers[Exclude<MapStateLayerNames, MethaneLayerIds>];
export type CommonInfrastructureLayerConfig = {
    [Property in keyof InfrastructureLayerConfig]: InfrastructureLayerConfig[Property];
};

export type LayerStateUpdate<LayerId extends MapStateLayerNames> = {
    layerId: LayerId;
    layerUpdates: Partial<MapStateLayers[LayerId]>;
};

/** New types, interfaces, mappings */

/** View state */
export interface ViewState {
    viewState: ViewStatePropsRedux;
    bbox: GeoJSON.BBox;
}

/** Methane Layer state */
export type MethaneLayerState = {
    [MethaneLayerIds.areaEmissionRaster]: AreaLayerState;
    [MethaneLayerIds.plumeEmissionRate]: FluxPlumeLayerState;
    [MethaneLayerIds.targets]: TargetLayerState;
};

/** OGI Layer state */
export type OGILayerState = {
    [OGILayerIds.pointInfrastructure]: GeoJsonPointLayerState;
    [OGILayerIds.pipelines]: GeoJsonLineLayerState;
    [OGILayerIds.basins]: GeoJsonPolygonLayerState;
    [OGILayerIds.tileInfrastructure]: TileInfrastructureLayerState;
};

export type OGILayerConfig = {
    layerState: OGILayerState;
    filters: MapFilters;
    ogiNearPlumesEnabled: boolean;
};

export type MethaneLayerUpdate<LayerId extends keyof MethaneLayerState> = {
    layerId: LayerId;
    layerUpdates: Partial<MethaneLayerState[LayerId]>;
};

export type OGILayerUpdate<LayerId extends keyof OGILayerState> = {
    layerId: LayerId;
    layerUpdates: Partial<OGILayerState[LayerId]>;
};

/** Global settings state */
export interface GlobalSettings {
    date: {
        global: number;
        // targetIds and their specific dates to display
        [targetId: string]: number | null | undefined;
    };
    platform: Platforms;
}

/** Map interface state */
export interface MapInterface {
    gestureHandling: google.maps.MapOptions['gestureHandling'];
    mapStyle?: (typeof MapStyles)[keyof typeof MapStyles];
    notifications: NotificationMessage[] | NotificationsLoading;
    ui: {
        isAreaEmissionsChipExpanded: boolean;
        isOgiOperatorFilterExpanded: boolean;
        isPlumeEmissionsChipExpanded: boolean;
        isOilAndGasTypeChipExpanded: boolean;
        isTargetEmissionsChipExpanded: boolean;
    };
}

export interface SelectedMethaneFeature {
    coordinates: GeoJSON.Position;
    targetDate: string;
    targetId: string;
    collectionId: STACCollection | null;
    itemId: string;
    featureType: UrlFeatureTypes | null;
    featureLat?: number;
    featureLng?: number;
}

export interface SelectedOGIFeature {
    object: GeoJSON.Feature | null;
}

/** Selected feature state. Currently only holds the selected methane feature */
export interface SelectedFeatureState {
    selectedMethaneFeature: SelectedMethaneFeature;
    selectedOGIFeature: SelectedOGIFeature;
    selectedPlume: StacFeatureWithLayerId<GeoJSON.Point, PlumeProperties> | null;
    activeCapture: {
        targetId: string;
        itemId: string;
    } | null;
    urlParamsInitialized: boolean;
    fetchingDrawerInfo: boolean;
}
