import { GeoJsonProperties, Geometry, Polygon } from 'geojson';
import { groupBy, orderBy } from 'lodash';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { log } from '@methanesat/log';
import { bboxPolygon, booleanIntersects, booleanWithin, polygon } from '@turf/turf';

import {
    selectEmissionsMapBBox,
    selectGlobalDate,
    selectMethaneProduct,
    selectPlatform,
    selectTargetDates
} from '../../../reducers';
import { useGetStacSearchQuery } from '../../../reducers/api';
import { RootState } from '../../../store';
import {
    AreaEmissionsProducts,
    CaptureFeature,
    CaptureFeatureProperties,
    Platforms,
    RasterPointFeature,
    STACCalendarData,
    STACCollection,
    SortDirection,
    StacFeature,
    StacSearch,
    StacSearchResponse
} from '../../../types';
import {
    captureFeaturesToDateBlocks,
    customFetch,
    getBasicSearch,
    getCollectionName,
    getDateRangeFromPlatform,
    getDayEnd,
    getIsValidDateString,
    getStacDataSource,
    getTimeBoundStacSearch,
    modifySearchCollection
} from '../../../utils';

/**
 * This file should contain ALL direct calls to STAC so we can
 * easily see which endpoints we are using.
 *
 * Write helper functions here and call them where they are needed.
 */

export enum DateRanges {
    Month = 'month',
    Quarter = 'quarter'
}
export type CapturePlusCount = StacFeature &
    GeoJSON.Feature<GeoJSON.Polygon, CaptureFeatureProperties & { platformTimespanCaptureCount?: number }>;

/**
 * Returns a list of target-captures for which to display data.
 * Returns a single capture/flight per target.
 * If a target has a specific date selected (in @param targetDates),
 * the capture/flight for that date will be returned.
 * If no target-specific date is given, the latest capture will be returned.
 */
export const getCapturesToDisplay = (
    captures: StacSearchResponse<Polygon, CaptureFeatureProperties>,
    targetDates: { [targetId: string]: number | null | undefined }
) => {
    const targetGroups = groupBy(captures.features, (f: CaptureFeature) => f.properties.target_id);
    const result: CapturePlusCount[] = [];
    for (const [targetId, targetCaptures] of Object.entries(targetGroups)) {
        let selection: CapturePlusCount | undefined;
        // get feature to display for each targetId
        if (targetDates[targetId]) {
            // if the target has a specific date set, use it
            selection = targetCaptures.find((f) => {
                const isValidDateString = getIsValidDateString(f.properties.datetime);
                if (isValidDateString && f.properties.datetime) {
                    const captureDate = new Date(f.properties.datetime);
                    const captureDateEOD = getDayEnd(captureDate).getTime();
                    return captureDateEOD === targetDates[targetId];
                }
                return false;
            });
        } else {
            // otherwise, get the most recent feature
            const sorted: CaptureFeature[] = orderBy(
                targetCaptures,
                (f) => (f.properties.datetime ? new Date(f.properties.datetime) : 0),
                'desc'
            );
            selection = sorted[0];
        }
        if (selection) {
            const updatedSelection = {
                ...selection,
                properties: {
                    ...selection.properties,
                    monthlyCaptureCount: targetCaptures.length
                }
            };
            result.push(updatedSelection);
        }
    }
    return result;
};

export type PointSourceStacResponse = StacSearchResponse & { id: string; collection?: string; targetId: string };
/**
 * Get point source GeoJSON for one stac item
 *
 * TODO: DP-3560 This feels like a potential risk. Add verification that the url is ours
 */
export const getPointSourceItem = async ({
    abortController,
    pointSourceUrl,
    stacCollectionId,
    stacItemId,
    targetId
}: {
    abortController: AbortController;
    pointSourceUrl?: string;
    stacCollectionId: STACCollection;
    stacItemId: string;
    targetId: string;
}): Promise<PointSourceStacResponse | null> => {
    // Not all collects have point sources (either none detected or failed QA).
    if (!pointSourceUrl) {
        return null;
    }

    // Don't try to fetch from private google cloud buckets
    if (pointSourceUrl.startsWith('gs')) {
        log.error(`Invalid private gs url for ${stacItemId}.  Could not retrieve Point Sources.`);
        return null;
    }

    try {
        const response = await customFetch(pointSourceUrl, { signal: abortController.signal });
        if (response.status < 200 || response.status >= 400) {
            log.error(`${response.status} received from cloud. Could not retrieve Point Sources`);
            return null;
        }
        const stacResponse = (await response.json()) as StacSearchResponse;
        return { ...stacResponse, id: stacItemId, collection: stacCollectionId, targetId };
    } catch (e) {
        const isAbortError = !!`${e}`.match(`AbortError|Hook cleanup`);
        if (isAbortError) {
            // User navigated away, which cancelled the request.
            return null;
        }
        log.error(`Error (getPointSourceItem): ${e}`);
        return null;
    }
};

export const getStacItem = async (itemId: string, product: AreaEmissionsProducts, platform: Platforms) => {
    const urls = getStacDataSource();
    const collectionId = getCollectionName(product, platform);
    const itemDataResponse = await customFetch(`${urls.targetsUrl}/collections/${collectionId}/items/${itemId}`);
    const data: StacFeature = await itemDataResponse.json();
    return data;
};

/**
 * Get data details from a gridded data source at a lat/lng point, along with
 * the properties for the capture that the point belongs to
 * @param lng
 * @param lat
 * @param endDate
 * @param itemId
 * @param product
 * @param platform
 * @returns the data values at that point
 */
export const getRasterPointDataFromStac = async (
    lng: number,
    lat: number,
    itemId: string,
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT
): Promise<RasterPointFeature | null> => {
    const urls = getStacDataSource();
    const collectionId = getCollectionName(product, platform);

    const itemData: StacFeature = await getStacItem(itemId, product, platform);

    /**
     * Quickly returns null if the user clicks outside of the bbox of the capture.  Otherwise,
     * an HTTP 500 is returned from TiTiler with the error 'Point is outside dataset bounds'.
     */
    const clickedPointGeometry: Geometry = { coordinates: [lng, lat], type: 'Point' };
    if (!booleanWithin(clickedPointGeometry, itemData)) {
        return null;
    }

    const cogData = await customFetch(
        `${urls.titilerUrl}/collections/${collectionId}/items/${itemId}/point/${lng},${lat}?assets=COG`
    );

    if (cogData.status < 200 || cogData.status >= 400) {
        log.error(`${cogData.status} received from pgSTAC-Titiler.  Could not access COG metadata.`);
        return null;
    }
    const emissionDataAtPoint = await cogData.json();

    return {
        id: itemId,
        type: 'Feature',
        geometry: {
            coordinates: [lng, lat],
            type: 'Point'
        },
        properties: {
            collectionEndTime: itemData.properties?.end_datetime,
            collectionStartTime: itemData.properties?.start_datetime,
            source: platform,
            collectionId: collectionId,
            sceneId: itemData.properties?.basin,
            methane: emissionDataAtPoint.values[0]
        }
    };
};

/**
 * This should run on L4 data only.
 */
export const useGetHistoricalSamplesForTarget = (targetId: string | null | undefined): STACCalendarData[] | null => {
    const historicalSamplesRef = useRef<StacSearchResponse<Geometry, GeoJsonProperties> | null>(null);
    const platform = useSelector(selectPlatform);
    let search: StacSearch | null = null;

    if (targetId) {
        /**
         * Note: search object is in the shape of the object for STAC search POST request.
         * See https://api.stacspec.org/v1.0.0-beta.3/item-search/#tag/Item-Search/operation/getItemSearch
         */
        search = {
            ...getBasicSearch(),
            filter: {
                op: '=',
                args: [
                    {
                        property: 'target_id'
                    },
                    targetId
                ]
            },
            collections: [getCollectionName(undefined, platform)],
            sortby: [
                {
                    field: 'properties.end_datetime',
                    direction: SortDirection.Ascending
                }
            ]
        };
    }
    // Get all data for the target.
    const { data } = useGetStacSearchQuery(search, { skip: !search });
    if (data?.features) {
        historicalSamplesRef.current = data;
        return data.features.map((feature) => captureFeaturesToDateBlocks(feature, platform));
    }
    if (historicalSamplesRef.current?.features) {
        return historicalSamplesRef.current.features.map((feature) => captureFeaturesToDateBlocks(feature, platform));
    }
    return [];
};

export const useGetCapturesWithinGlobalTimeRange = () => {
    const urlParamsInitialized = useSelector(
        // TODO: this seems like an odd place for this state
        (state: RootState) => state.pages.emissions.selectedFeature.urlParamsInitialized
    );
    const platform = useSelector(selectPlatform);
    const product = useSelector(selectMethaneProduct);
    const globalDate = useSelector(selectGlobalDate);

    const stacSearch = useMemo(() => {
        const dateRange = getDateRangeFromPlatform(platform);
        const initialSearch = modifySearchCollection(product, platform);
        if (urlParamsInitialized) return getTimeBoundStacSearch(new Date(globalDate), initialSearch, dateRange);
        return null;
    }, [platform, product, globalDate, urlParamsInitialized]);

    // Fetches the layer data that is passed to the `targetLayers` and `methaneLayers` hooks
    const { data: featureList = null, isFetching } = useGetStacSearchQuery(stacSearch, {
        skip: stacSearch === null ? true : false
    });
    return { featureList, isFetching };
};

/**
 * Used to determine when to show mini target date selectors -
 * i.e. when only one target is within the current viewport
 *
 * @param captures The list of captures currently loaded, based on global date selection & target-specific dates
 * @returns The list of captures loaded thata are visible within the map's viewport.
 *
 */
export const useGetCapturesWithinViewport = (
    captures: StacSearchResponse<Polygon, CaptureFeatureProperties> | null
) => {
    const targetDates = useSelector(selectTargetDates);
    const bbox = useSelector(selectEmissionsMapBBox);
    const capturesWithinViewport = useMemo(() => {
        if (!captures || !captures.features) return [];
        const viewportPolygon = bboxPolygon(bbox);
        const capturesDisplayed = getCapturesToDisplay(captures, targetDates);
        return capturesDisplayed.filter((capture) => {
            const capturePolygon = polygon(capture.geometry.coordinates);
            return booleanIntersects(capturePolygon, viewportPolygon);
        });
    }, [captures, targetDates, bbox]);

    return capturesWithinViewport;
};
